<template>
  <div/>
</template>
<script>
export default {
  mounted () {
    const auth = this.$cookies.get('auth')
    this.$cookies.remove('auth')
    // console.log(auth)
    if (!auth) {
      this.$router.push('/login')
    }
    this.$store
    .dispatch("user/loginSuccess", auth)
    .then(() => {
        this.$router.push("/dashboard")       
        })
    // eslint-disable-next-line no-console
    .catch((error) => console.log(error));    
  },
  methods: {}
}
</script>